import React, { useState, useEffect, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
// konva
import { Stage, Layer, Rect, Line } from 'react-konva';
import useMouse from '@react-hook/mouse-position';
// components
import { Rectangle } from './Item/Item';
import { StaticItem } from './Item/StaticItem';
import SelectionPane from './SelectionPane/SelectionPane';
import Popup from '../components/Popup/Popup';
import { itemScale, StageData, FloorPlanData } from './Item/ItemData';
import { ref_floorplanDetailsData, floorplanStorageBaseURL } from '../components/Data/FloorplanData';
import { fetchDataFromFirebase } from '../components/Utilities/Utilities';
// material UI
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// CSS
import './Editor.css';
import '../App.css';
// image
import companyLogo from '../Assets/logo.svg';
// others
const axios = require('axios');
const selectionPaneWidth = 212;
const minZoom = 0.25;

/* Editor */
export default function Editor(props) {

  /* props/consts/vars */
  const { userData, resetEverything } = props;
  const [rectangles, setRectangles] = useState([[], [], [], [], [], [], []]); // layers
  const [selectedId, selectItem] = useState(null);
  const [additionalComments, setAdditionalComments] = useState("");
  const [initState, setInitState] = useState("init stage"); // 1) init stage, 2) loading stage, 3) success
  const [submitState, setSubmitState] = useState("none"); // 1) none, 2) confirm submit, 3) loading, 4) sent success
  const [floorplanRef, setFloorplanRef] = useState("");
  const [dataState, setDataState] = useState("success");
  const [developmentDetails, setDevelopmentDetails] = useState(null); // kitchen wall details
  const stageRef = useRef();
  const divRef = useRef(null);
  const mouse = useMouse(divRef, {
    enterDelay: 0,
    leaveDelay: 0,
    fps: 75 // for 165hz monitor max
  });
  const [stageDimensions, setStageDimensions] = useState({
    originalW: StageData.standardW, // scale of 1
    originalH: StageData.standardH, // scale of 1
    w: StageData.standardW,
    h: StageData.standardH,
    scaleX: 1.0,
    scaleY: 1.0
  });
  const [rightClickMenu, setRightClickMenu] = useState({
    id: "",
    layer: -1,
    x: 0.0,
    y: 0.0,
    shapeRef: null
  });
  const [dim, setDim] = useState(false);
  const [enableKitchenWall, setEnableKitchenWall] = useState(true);

  /* functions */
  useEffect(() => {
    // 1) draw the stage first
    if (initState === "init stage" && dataState === "success") {
      // fetch development details
      fetchDataFromFirebase(!developmentDetails, `details/${userData.developmentId}`, dataState, setDataState, (docData) => {
        setDevelopmentDetails(docData);
        setInitState("loading stage");
        determineStageDimensions();
        setFloorplanRef(`${floorplanStorageBaseURL}/${userData.developmentId}/jpg/${userData.floorPlanType}-nokitchenwall.jpg`);
        setDataState("success");
        setInitState("success");
      });
      // test ver
      // setInitState("loading stage");
      // determineStageDimensions();
      // setFloorplanRef(`${floorplanStorageBaseURL}/${userData.developmentId}/jpg/${userData.floorPlanType}-nokitchenwall.jpg`);
      // setDataState("success");
      // setInitState("success");
    }
  }, [rectangles, selectedId, initState, stageDimensions, rightClickMenu]);

  function zoomStageStep(zoomIn) {
    if (stageRef.current) {
      if (!zoomIn && stageDimensions.scaleX === minZoom && stageDimensions.scaleY === minZoom) {
        return;
      }
      let zoomTheta = zoomIn ? 0.25 : -0.25;
      zoomStage(zoomTheta, minZoom);
    }
  }

  function zoomStage(zoomTheta, minZoom) {
    let newStageDimensions = cloneDeep(stageDimensions);
    newStageDimensions.scaleX = Math.max(minZoom, newStageDimensions.scaleX + zoomTheta);
    newStageDimensions.scaleY = Math.max(minZoom, newStageDimensions.scaleY + zoomTheta);
    newStageDimensions.w = newStageDimensions.originalW * newStageDimensions.scaleX;
    newStageDimensions.h = newStageDimensions.originalH * newStageDimensions.scaleY;
    setStageDimensions(newStageDimensions);
  }

  function trimScaleStageBeforeScreenshot()
  {
    if (stageRef && stageRef.current) {
      // trim
      let newWidth = stageDimensions.w * (2.0 / 3.0);
      let newHeight = stageDimensions.h;
      const newScaleX = 1.0;
      const newScaleY = 1.0;
      newWidth *= (newScaleX / stageDimensions.scaleX);
      newHeight *= (newScaleY / stageDimensions.scaleY);
      
      // apply values
      stageRef.current.width(newWidth);
      stageRef.current.height(newWidth);
      stageRef.current.scale({ x: newScaleX, y: newScaleY });
    }
  }

  function determineStageDimensions() {
    // this function is to set the values before drawing stage
    // ratio of default height and current window height
    const initialZoomRatio = divRef.current.clientHeight / StageData.standardH;
    let newStageDimensions = cloneDeep(stageDimensions);
    newStageDimensions.scaleX = initialZoomRatio;
    newStageDimensions.scaleY = initialZoomRatio;
    newStageDimensions.w = newStageDimensions.originalW * initialZoomRatio;
    newStageDimensions.h = newStageDimensions.originalH * initialZoomRatio;
    setStageDimensions(newStageDimensions);
  }

  function checkDeselect(e, eventType) {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectItem(null);
    }
    if (eventType === "onMouseDown") {
      freeRightClickMenu();
    }
  };

  function configureRightClickMenu(id, layer, x, y, shapeRef) {
    setRightClickMenu({
      id: id,
      layer: layer,
      x: x,
      y: y,
      shapeRef: shapeRef
    });
  }

  function freeRightClickMenu() {
    setRightClickMenu({
      id: "",
      layer: -1,
      x: 0.0,
      y: 0.0,
      shapeRef: null
    });
  }

  function onDivContextMenu(e) {
    // to prevent default right click menu
    e.preventDefault();
  }

  function onClickItem(e, id, layer, shapeRef) {
    // right click
    if (e && e.evt.button === 2 && e.type === "click") {
      configureRightClickMenu(id, layer, mouse.x, mouse.y, shapeRef);
    }
    selectItem(id);
  }

  function deleteItem(id, layer) {
    let newRectangles = rectangles.slice();
    let deleteIdx = newRectangles[layer].findIndex((e) => { return id === e.id });
    newRectangles[layer].splice(deleteIdx, 1);
    setRectangles(newRectangles);
    freeRightClickMenu();
  }

  function rotateItem(id, isLeft) {
    // manipulation of shape item must be done here as konva can't handle pure DOM objects
    if (rightClickMenu.id !== "" && rightClickMenu.shapeRef) {
      let rawAngle = rightClickMenu.shapeRef.getRotation();
      let currAngle = rawAngle % 360.0;  // curr angle positive
      currAngle = currAngle < 0.0 ? 360.0 + currAngle : currAngle;
      let theta = 0.0;
      if (currAngle >= 0.0 && currAngle < 90.0) {
        theta = !isLeft ? 90.0 - currAngle : -(currAngle - 0.0);
      } else if (currAngle >= 90.0 && currAngle < 180.0) {
        theta = !isLeft ? 180.0 - currAngle : -(currAngle - 90.0);
      } else if (currAngle >= 180.0 && currAngle < 270.0) {
        theta = !isLeft ? 270.0 - currAngle : -(currAngle - 180.0);
      } else {
        theta = !isLeft ? 360.0 - currAngle : -(currAngle - 270.0);
      }
      if (theta === 0.0 && isLeft) {
        theta = -90.0;
      }
      rightClickMenu.shapeRef.rotate(theta);
    }
  }

  function generateNewRect(x, y, imgData) {
    // make sure is in range
    if (imgData.layer < 0 || imgData.layer >= rectangles.length) {
      return;
    }
    // create new rect
    let newRectangles = rectangles.slice();
    // params
    newRectangles[imgData.layer].push({
      x: -1000,
      y: -1000,
      width: imgData.width * itemScale,
      height: imgData.height * itemScale,
      offsetX: imgData.width * itemScale / 2,
      offsetY: imgData.height * itemScale / 2,
      stroke: 'red',
      strokeWidth: 2,
      strokeEnabled: false,
      id: 'rect' + new Date().getTime(),
      dragOnCreate: true,
      imgData: imgData
    });
    setRectangles(newRectangles);
  }

  function b64toBlob(dataURI, type, callback) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: type });
  }

  function resetEditor() {

    // reset editor stuff
    setRectangles([[], [], [], [], [], [], []]); // layers
    selectItem(null);
    setAdditionalComments("");
    setInitState("init stage"); // 1) init stage, 2) loading stage, 3) success
    setSubmitState("none"); // 1) none, 2) confirm submit, 3) loading, 4) sent success
    setFloorplanRef("");
    setStageDimensions({
      originalW: StageData.standardW, // scale of 1
      originalH: StageData.standardH, // scale of 1
      w: StageData.standardW,
      h: StageData.standardH,
      scaleX: 1.0,
      scaleY: 1.0
    });
    setRightClickMenu({
      id: "",
      layer: -1,
      x: 0.0,
      y: 0.0,
      shapeRef: null
    });
    setDim(false);
    setEnableKitchenWall(true);

    // set on stage ref
    if (stageRef)
    {
      stageRef.current.width(StageData.standardW);
      stageRef.current.height(StageData.standardH);
      stageRef.current.scale({ x: 1.0, y: 1.0 });
    }

    // reset parent stuff
    resetEverything();
  }

  function drawPopup() {
    let open = submitState !== "none";
    if (!open) {
      return null;
    }

    let onProceedFunc = null;
    let title = "";
    let mainText = "";
    let onClose = () => {
      setSubmitState("none");
    };
    if (submitState === "confirm submit") { // proceed to submit floorplan
      title = "Submit your floorplan";
      mainText = "Confirm submission of floorplan?";
      onProceedFunc = () => {
        trimScaleStageBeforeScreenshot();  // to prevent screenshot from having excess width (whitespaces)
        submitFloorplanToBackend();
      };
    } else if (submitState === "loading") { // loading state
      title = "Submit your floorplan";
      mainText = "";
      onClose = null; // during loading we remove cancel button
    } else if (submitState === "sent success") { // already submit, go back to main menu
      title = "Floorplan submitted";
      mainText = "Thank you for your submission. We will get in touch with you soon.";
      onClose = null; // after sent user just need click proceed again to go back page 1
      onProceedFunc = () => {
        // go back to 1st screen
        setSubmitState("none");
        resetEditor();
      };
    }
    // fields
    const popupFields = [
      {
        label: "Additional Comments",
        type: "textarea",
        onBlur: (text) => {
          setAdditionalComments(text);
        }
      }];
    return <Popup
      open={open}
      isLoading={submitState === "loading"}
      title={title}
      mainText={mainText}
      fields={submitState === "confirm submit" ? popupFields : null}
      onClose={onClose}
      onProceed={onProceedFunc}
    />
  }

  function submitFloorplanToBackend() {
    // set submit state to loading
    setSubmitState("loading");
    // convert stage to png
    const imgSrc = stageRef.current.toDataURL({
      mimeType: "image/png",
      // quality: 1,
      pixelRatio: 2
    });
    const imgBlob = b64toBlob(imgSrc, "image/png");
    let fd = new FormData();
    fd.append('customerName', userData.customerName);
    fd.append('customerPhone', userData.customerPhone);
    fd.append('customerDevelopment', userData.developmentLabel);
    fd.append('customerBlock', userData.blockLabel);
    fd.append('customerRoomCount', userData.roomCountLabel);
    fd.append('customerFloorPlan', userData.floorPlanLabel);
    fd.append('additionalComments', additionalComments);
    fd.append('floorplanFile', imgBlob, `floorplan${new Date().getTime()}.png`);

    const url = "https://us-central1-sg-interior-tool.cloudfunctions.net/widgets/floorplan";
    // const url = 'http://localhost:5000/floorplan';
    // real
    axios.post(url, fd)
      .then(function (response) {
        // success
        console.log("success");
        setSubmitState("sent success");
      })
      .catch(function (error) {
        console.log(error);
      });
    // mock submit data
    // const interval = setInterval(() => {
    //   setSubmitState("sent success");
    //   clearInterval(interval);
    // }, 1000);
  }

  /* render */
  return (
    <div className="editor-container flex-horizontal align-center overflow-hidden" onContextMenu={(e) => onDivContextMenu(e)}>

      {/* stage */}
      <div className="editor-stage-container overflow-scroll" ref={divRef}>
        {initState === "success" ? <Stage
          width={Math.max(stageDimensions.w, stageDimensions.w)}
          height={Math.max(stageDimensions.h, stageDimensions.h)}
          scaleX={stageDimensions.scaleX}
          scaleY={stageDimensions.scaleY}
          onMouseDown={(e) => checkDeselect(e, "onMouseDown")}
          onTouchStart={(e) => checkDeselect(e, "onTouchStart")}
          ref={stageRef}
        >
          {/* floorplan layer */}
          <Layer
            listening={false}
            opacity={dim ? 0.4 : 1.0}
          >
            {/* floorplan */}
            <StaticItem
              imgData={floorplanRef}
              shapeProps={{
                x: StageData.x,
                y: StageData.y,
                width: FloorPlanData.width,
                height: FloorPlanData.height,
                id: 'floorplan 01',
                stroke: 'red',
                strokeWidth: 1,
                strokeEnabled: false,
                imgData: floorplanRef
              }}
            />
            {/* kitchen wall */}
            {enableKitchenWall && developmentDetails && developmentDetails[userData.floorPlanType].kitchenWall ? <Line
              points={developmentDetails[userData.floorPlanType].kitchenWall}
              fill='#ff1100'
              closed
            /> : null}
          </Layer>

          {/* shelf layer */}
          <Layer>
            {rectangles.map((rectLayer, k) => {
              return (
                rectLayer.map((rect, i) => {
                  return <Rectangle
                    key={rect.id}
                    shapeProps={rect}
                    mouse={mouse}
                    divRef={divRef}
                    isSelected={rect.id === selectedId}
                    onSelect={(e, shapeRef) => {
                      onClickItem(e, rect.id, rect.imgData.layer, shapeRef);
                    }}
                    onChange={(newAttrs) => {
                      const rects = rectangles.slice();
                      rects[k][i] = newAttrs;
                      setRectangles(rects);
                    }}
                    dragOnCreate={rect.dragOnCreate}
                    imgData={rect.imgData}
                  />
                })
              );
              // return rect
            })}
          </Layer>
        </Stage> : null}
      </div>

      {/* item draw */}
      <div className="editor-selection-container">
        <SelectionPane
          createNewItem={generateNewRect}
          onClickSubmit={() => { setSubmitState("confirm submit") }}
        />
      </div>

      {/* right click menu */}
      {
        rightClickMenu.id !== "" ? <div className="editor-right-click-menu flex-vertical" style={{ top: rightClickMenu.y, left: rightClickMenu.x }}>
          <div className="editor-right-click-button" onClick={() => rotateItem(rightClickMenu.id, true)}>
            <div className="standard-text text-unselectable">Rotate anti-clockwise</div>
          </div>
          <div className="border-bottom" />
          <div className="editor-right-click-button" onClick={() => rotateItem(rightClickMenu.id, false)}>
            <div className="standard-text text-unselectable">Rotate clockwise</div>
          </div>
          <div className="border-bottom" />
          <div className="editor-right-click-button" onClick={() => deleteItem(rightClickMenu.id, rightClickMenu.layer)}>
            <div className="standard-text text-unselectable">Delete</div>
          </div>
        </div> : null}

      {/* overlay */}
      <div className="editor-overlay-container">
        {/* logo */}
        <div className="editor-display-logo">
          <div style={{
            width: '170px', height: '70px', backgroundImage: `url(${companyLogo})`,
            backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'
          }} />
        </div>
        <div className="editor-zoom-options-container" style={{ top: '0px', right: `${selectionPaneWidth + 15}px` }}>
          {/* checkboxes */}
          <div className="horizontal-orientation align-center" style={{ padding: "2px 8px 0 0" }}>
            {/* dim background level */}
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  style={{ userSelect: "none" }}
                  control={<Checkbox
                    checked={dim}
                    color="default"
                    onChange={() => setDim(!dim)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />}
                  label="Dim"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
            {/* kitchen wall toggle */}
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  style={{ userSelect: "none" }}
                  control={<Checkbox
                    disabled={!developmentDetails || !developmentDetails[userData.floorPlanType].kitchenWall}
                    checked={enableKitchenWall}
                    color="default"
                    onChange={() => setEnableKitchenWall(!enableKitchenWall)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />}
                  label="Kitchen wall"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </div>
          {/* buttons */}
          <div className="flex-vertical" style={{ paddingTop: "10px", width: '125px', backgroundColor: "rgba(0, 0, 0, 0)" }}>
            <div className="horizontal-orientation align-center">
              <div className="editor-zoom-options-button align-center" onClick={() => zoomStageStep(true)}>
                <AddIcon fontSize="small" />
              </div>
              <div className="editor-zoom-options-button align-center" onClick={() => zoomStageStep(false)}>
                <RemoveIcon fontSize="small" />
              </div>
            </div>
            <div className="standard-text text-unselectable">{`zoom: ${stageDimensions.scaleX.toFixed(2)}x`}</div>
          </div>
        </div>
      </div>

      {/* popup */}
      {drawPopup()}

    </div>
  );
}