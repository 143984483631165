// fans
import fans_asset_1 from '../../Assets/symbols/fans/Asset_1.svg';
// study room
import studyRoom_asset_1 from '../../Assets/symbols/studyRoom/Asset_1.svg';
import studyRoom_asset_2 from '../../Assets/symbols/studyRoom/Asset_2.svg';
import studyRoom_asset_3 from '../../Assets/symbols/studyRoom/Asset_3.svg';
import studyRoom_asset_4 from '../../Assets/symbols/studyRoom/Asset_4.svg';
import studyRoom_asset_5 from '../../Assets/symbols/studyRoom/Asset_5.svg';
import studyRoom_asset_6 from '../../Assets/symbols/studyRoom/Asset_6.svg';
// doors
import doors_asset_1 from '../../Assets/symbols/doors/Asset_1.svg';
import doors_asset_2 from '../../Assets/symbols/doors/Asset_2.svg';
import doors_asset_3 from '../../Assets/symbols/doors/Asset_3.svg';
import doors_asset_4 from '../../Assets/symbols/doors/Asset_4.svg';
import doors_asset_5 from '../../Assets/symbols/doors/Asset_5.svg';
import doors_asset_6 from '../../Assets/symbols/doors/Asset_6.svg';
import doors_asset_7 from '../../Assets/symbols/doors/Asset_7.svg';
import doors_asset_8 from '../../Assets/symbols/doors/Asset_8.svg';
import doors_asset_9 from '../../Assets/symbols/doors/Asset_9.svg';
import doors_asset_10 from '../../Assets/symbols/doors/Asset_10.svg';
import doors_asset_11 from '../../Assets/symbols/doors/Asset_11.svg';
import doors_asset_12 from '../../Assets/symbols/doors/Asset_12.svg';
import doors_asset_13 from '../../Assets/symbols/doors/Asset_13.svg';
import doors_asset_14 from '../../Assets/symbols/doors/Asset_14.svg';
// dining area
import diningArea_asset_1 from '../../Assets/symbols/diningArea/Asset_1.svg';
import diningArea_asset_2 from '../../Assets/symbols/diningArea/Asset_2.svg';
import diningArea_asset_3 from '../../Assets/symbols/diningArea/Asset_3.svg';
import diningArea_asset_4 from '../../Assets/symbols/diningArea/Asset_4.svg';
import diningArea_asset_5 from '../../Assets/symbols/diningArea/Asset_5.svg';
import diningArea_asset_6 from '../../Assets/symbols/diningArea/Asset_6.svg';
import diningArea_asset_7 from '../../Assets/symbols/diningArea/Asset_7.svg';
import diningArea_asset_8 from '../../Assets/symbols/diningArea/Asset_8.svg';
import diningArea_asset_9 from '../../Assets/symbols/diningArea/Asset_9.svg';
import diningArea_asset_10 from '../../Assets/symbols/diningArea/Asset_10.svg';
import diningArea_asset_11 from '../../Assets/symbols/diningArea/Asset_11.svg';
import diningArea_asset_12 from '../../Assets/symbols/diningArea/Asset_12.svg';
import diningArea_asset_13 from '../../Assets/symbols/diningArea/Asset_13.svg';
import diningArea_asset_14 from '../../Assets/symbols/diningArea/Asset_14.svg';
import diningArea_asset_15 from '../../Assets/symbols/diningArea/Asset_15.svg';
import diningArea_asset_16 from '../../Assets/symbols/diningArea/Asset_16.svg';
import diningArea_asset_17 from '../../Assets/symbols/diningArea/Asset_17.svg';
import diningArea_asset_18 from '../../Assets/symbols/diningArea/Asset_18.svg';
import diningArea_asset_19 from '../../Assets/symbols/diningArea/Asset_19.svg';
import diningArea_asset_20 from '../../Assets/symbols/diningArea/Asset_20.svg';
// bedroom
import bedroom_asset_1 from '../../Assets/symbols/bedroom/Asset_1.svg';
import bedroom_asset_2 from '../../Assets/symbols/bedroom/Asset_2.svg';
import bedroom_asset_3 from '../../Assets/symbols/bedroom/Asset_3.svg';
import bedroom_asset_4 from '../../Assets/symbols/bedroom/Asset_4.svg';
import bedroom_asset_5 from '../../Assets/symbols/bedroom/Asset_5.svg';
import bedroom_asset_6 from '../../Assets/symbols/bedroom/Asset_6.svg';
import bedroom_asset_7 from '../../Assets/symbols/bedroom/Asset_7.svg';
import bedroom_asset_8 from '../../Assets/symbols/bedroom/Asset_8.svg';
import bedroom_asset_9 from '../../Assets/symbols/bedroom/Asset_9.svg';
import bedroom_asset_10 from '../../Assets/symbols/bedroom/Asset_10.svg';
// import bedroom_asset_11 from '../../Assets/symbols/bedroom/Asset_11.svg';
import bedroom_asset_12 from '../../Assets/symbols/bedroom/Asset_12.svg';
import bedroom_asset_13 from '../../Assets/symbols/bedroom/Asset_13.svg';
import bedroom_asset_14 from '../../Assets/symbols/bedroom/Asset_14.svg';
import bedroom_asset_15 from '../../Assets/symbols/bedroom/Asset_15.svg';
import bedroom_asset_16 from '../../Assets/symbols/bedroom/Asset_16.svg';
// living area
import livingArea_asset_1 from '../../Assets/symbols/livingArea/Asset_1.svg';
import livingArea_asset_2 from '../../Assets/symbols/livingArea/Asset_2.svg';
import livingArea_asset_3 from '../../Assets/symbols/livingArea/Asset_3.svg';
import livingArea_asset_4 from '../../Assets/symbols/livingArea/Asset_4.svg';
import livingArea_asset_5 from '../../Assets/symbols/livingArea/Asset_5.svg';
import livingArea_asset_6 from '../../Assets/symbols/livingArea/Asset_6.svg';
import livingArea_asset_7 from '../../Assets/symbols/livingArea/Asset_7.svg';
import livingArea_asset_8 from '../../Assets/symbols/livingArea/Asset_8.svg';
import livingArea_asset_9 from '../../Assets/symbols/livingArea/Asset_9.svg';
import livingArea_asset_10 from '../../Assets/symbols/livingArea/Asset_10.svg';
import livingArea_asset_11 from '../../Assets/symbols/livingArea/Asset_11.svg';
import livingArea_asset_12 from '../../Assets/symbols/livingArea/Asset_12.svg';
import livingArea_asset_13 from '../../Assets/symbols/livingArea/Asset_13.svg';
import livingArea_asset_14 from '../../Assets/symbols/livingArea/Asset_14.svg';
import livingArea_asset_15 from '../../Assets/symbols/livingArea/Asset_15.svg';
import livingArea_asset_16 from '../../Assets/symbols/livingArea/Asset_16.svg';
import livingArea_asset_17 from '../../Assets/symbols/livingArea/Asset_17.svg';
import livingArea_asset_18 from '../../Assets/symbols/livingArea/Asset_18.svg';
import livingArea_asset_19 from '../../Assets/symbols/livingArea/Asset_19.svg';
import livingArea_asset_20 from '../../Assets/symbols/livingArea/Asset_20.svg';
import livingArea_asset_21 from '../../Assets/symbols/livingArea/Asset_21.svg';
import livingArea_asset_22 from '../../Assets/symbols/livingArea/Asset_22.svg';
import livingArea_asset_23 from '../../Assets/symbols/livingArea/Asset_23.svg';
import livingArea_asset_24 from '../../Assets/symbols/livingArea/Asset_24.svg';
// import livingArea_asset_25 from '../../Assets/symbols/livingArea/Asset_25.svg';
import livingArea_asset_26 from '../../Assets/symbols/livingArea/Asset_26.svg';
import livingArea_asset_27 from '../../Assets/symbols/livingArea/Asset_27.svg';
import livingArea_asset_28 from '../../Assets/symbols/livingArea/Asset_28.svg';
import livingArea_asset_29 from '../../Assets/symbols/livingArea/Asset_29.svg';
import livingArea_asset_30 from '../../Assets/symbols/livingArea/Asset_30.svg';
import livingArea_asset_31 from '../../Assets/symbols/livingArea/Asset_31.svg';
import livingArea_asset_32 from '../../Assets/symbols/livingArea/Asset_32.svg';
import livingArea_asset_33 from '../../Assets/symbols/livingArea/Asset_33.svg';
import livingArea_asset_34 from '../../Assets/symbols/livingArea/Asset_34.svg';
import livingArea_asset_35 from '../../Assets/symbols/livingArea/Asset_35.svg';
import livingArea_asset_36 from '../../Assets/symbols/livingArea/Asset_36.svg';
import livingArea_asset_37 from '../../Assets/symbols/livingArea/Asset_37.svg';
import livingArea_asset_38 from '../../Assets/symbols/livingArea/Asset_38.svg';
import livingArea_asset_39 from '../../Assets/symbols/livingArea/Asset_39.svg';
import livingArea_asset_40 from '../../Assets/symbols/livingArea/Asset_40.svg';
import livingArea_asset_41 from '../../Assets/symbols/livingArea/Asset_41.svg';
import livingArea_asset_42 from '../../Assets/symbols/livingArea/Asset_42.svg';
import livingArea_asset_43 from '../../Assets/symbols/livingArea/Asset_43.svg';
import livingArea_asset_44 from '../../Assets/symbols/livingArea/Asset_44.svg';
import livingArea_asset_45 from '../../Assets/symbols/livingArea/Asset_45.svg';
import livingArea_asset_46 from '../../Assets/symbols/livingArea/Asset_46.svg';
import livingArea_asset_47 from '../../Assets/symbols/livingArea/Asset_47.svg';
import livingArea_asset_48 from '../../Assets/symbols/livingArea/Asset_48.svg';
import livingArea_asset_49 from '../../Assets/symbols/livingArea/Asset_49.svg';
import livingArea_asset_50 from '../../Assets/symbols/livingArea/Asset_50.svg';
// kitchen
import kitchen_asset_1 from '../../Assets/symbols/kitchen/Asset_1.svg';
import kitchen_asset_2 from '../../Assets/symbols/kitchen/Asset_2.svg';
import kitchen_asset_3 from '../../Assets/symbols/kitchen/Asset_3.svg';
import kitchen_asset_4 from '../../Assets/symbols/kitchen/Asset_4.svg';
import kitchen_asset_5 from '../../Assets/symbols/kitchen/Asset_5.svg';
import kitchen_asset_6 from '../../Assets/symbols/kitchen/Asset_6.svg';
import kitchen_asset_7 from '../../Assets/symbols/kitchen/Asset_7.svg';
import kitchen_asset_8 from '../../Assets/symbols/kitchen/Asset_8.svg';
import kitchen_asset_9 from '../../Assets/symbols/kitchen/Asset_9.svg';
import kitchen_asset_10 from '../../Assets/symbols/kitchen/Asset_10.svg';
import kitchen_asset_11 from '../../Assets/symbols/kitchen/Asset_11.svg';
import kitchen_asset_12 from '../../Assets/symbols/kitchen/Asset_12.svg';
import kitchen_asset_13 from '../../Assets/symbols/kitchen/Asset_13.svg';
import kitchen_asset_14 from '../../Assets/symbols/kitchen/Asset_14.svg';
import kitchen_asset_15 from '../../Assets/symbols/kitchen/Asset_15.svg';
import kitchen_asset_16 from '../../Assets/symbols/kitchen/Asset_16.svg';
import kitchen_asset_17 from '../../Assets/symbols/kitchen/Asset_17.svg';
import kitchen_asset_18 from '../../Assets/symbols/kitchen/Asset_18.svg';
import kitchen_asset_19 from '../../Assets/symbols/kitchen/Asset_19.svg';
import kitchen_asset_20 from '../../Assets/symbols/kitchen/Asset_20.svg';
import kitchen_asset_21 from '../../Assets/symbols/kitchen/Asset_21.svg';
import kitchen_asset_22 from '../../Assets/symbols/kitchen/Asset_22.svg';
// bathroom
import bathroom_asset_1 from '../../Assets/symbols/bathroom/Asset_1.svg';
import bathroom_asset_2 from '../../Assets/symbols/bathroom/Asset_2.svg';
import bathroom_asset_3 from '../../Assets/symbols/bathroom/Asset_3.svg';
import bathroom_asset_4 from '../../Assets/symbols/bathroom/Asset_4.svg';
import bathroom_asset_5 from '../../Assets/symbols/bathroom/Asset_5.svg';
import bathroom_asset_6 from '../../Assets/symbols/bathroom/Asset_6.svg';
import bathroom_asset_7 from '../../Assets/symbols/bathroom/Asset_7.svg';
import bathroom_asset_8 from '../../Assets/symbols/bathroom/Asset_8.svg';
import bathroom_asset_9 from '../../Assets/symbols/bathroom/Asset_9.svg';

// for objects (see symbol-scaling.txt for scaling formula)
// all floorplan images must be of the same dimensions
const floorplanRawWidth = 4491;
const floorplanRawHeight = 3175;
const floorplanH = 959;  // size in pixels we want to display
const floorplanWHRatio = floorplanRawWidth / floorplanRawHeight;
export const itemScale = floorplanH / floorplanRawHeight;

/* item data */
// please put the item's actual px size here
export const ItemsData = {
  fans: {
    label: "Fans",
    data: [
      {
        label: "ceiling fan",
        icon: fans_asset_1,
        width: 201,
        height: 202,
        layer: 6
      }
    ]
  },
  studyRoom: {
    label: "Study Room",
    data: [
      {
        label: "desktop",
        icon: studyRoom_asset_1,
        width: 140,
        height: 53,
        layer: 2
      },
      {
        label: "suspended shelving",
        icon: studyRoom_asset_2,
        width: 186,
        height: 64,
        layer: 3
      },
      {
        label: "chair w/ armrest",
        icon: studyRoom_asset_3,
        width: 99,
        height: 95,
        layer: 1
      },
      {
        label: "chair w/o armrest",
        icon: studyRoom_asset_4,
        width: 87,
        height: 95,
        layer: 1
      },
      {
        label: "stool",
        icon: studyRoom_asset_5,
        width: 75,
        height: 76,
        layer: 1
      },
      {
        label: "office chair",
        icon: studyRoom_asset_6,
        width: 105,
        height: 103,
        layer: 1
      }
    ]
  },
  doors: {
    label: "Doors",
    data: [
      {
        label: "800mm",
        icon: doors_asset_1,
        width: 125,
        height: 125,
        layer: 1
      },
      {
        label: "900mm",
        icon: doors_asset_2,
        width: 140,
        height: 140,
        layer: 1
      },
      {
        label: "1000mm",
        icon: doors_asset_3,
        width: 155,
        height: 155,
        layer: 1
      },
      {
        label: "1100mm",
        icon: doors_asset_4,
        width: 170,
        height: 170,
        layer: 1
      },
      {
        label: "800mm",
        icon: doors_asset_5,
        width: 125,
        height: 125,
        layer: 1
      },
      {
        label: "900mm",
        icon: doors_asset_6,
        width: 140,
        height: 140,
        layer: 1
      },
      {
        label: "1000mm",
        icon: doors_asset_7,
        width: 155,
        height: 155,
        layer: 1
      },
      {
        label: "1100mm",
        icon: doors_asset_8,
        width: 170,
        height: 170,
        layer: 1
      },
      {
        label: "swing & slide door",
        icon: doors_asset_9,
        width: 141,
        height: 88,
        layer: 1
      },
      {
        label: "swing & slide door",
        icon: doors_asset_10,
        width: 141,
        height: 88,
        layer: 1
      },
      {
        label: "bi-fold door",
        icon: doors_asset_11,
        width: 138,
        height: 54,
        layer: 1
      },
      {
        label: "bi-fold door",
        icon: doors_asset_12,
        width: 138,
        height: 54,
        layer: 1
      },
      {
        label: "sliding door",
        icon: doors_asset_13,
        width: 138,
        height: 29,
        layer: 1
      },
      {
        label: "sliding door",
        icon: doors_asset_14,
        width: 138,
        height: 29,
        layer: 1
      }
    ]
  },
  diningArea: {
    label: "Dining Area",
    data: [
      {
        label: "table w/ chairs",
        icon: diningArea_asset_1,
        width: 331,
        height: 224,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_2,
        width: 217,
        height: 202,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_3,
        width: 125,
        height: 126,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_4,
        width: 306,
        height: 269,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_5,
        width: 304,
        height: 323,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_6,
        width: 334,
        height: 329,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_7,
        width: 308,
        height: 329,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_8,
        width: 299,
        height: 232,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_9,
        width: 313,
        height: 245,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_10,
        width: 221,
        height: 217,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_11,
        width: 333,
        height: 260,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_12,
        width: 202,
        height: 210,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_13,
        width: 328,
        height: 233,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_14,
        width: 213,
        height: 212,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_15,
        width: 190,
        height: 190,
        layer: 1
      },
      {
        label: "table w/ chairs",
        icon: diningArea_asset_16,
        width: 273,
        height: 237,
        layer: 1
      },
      {
        label: "table",
        icon: diningArea_asset_17,
        width: 157,
        height: 157,
        layer: 1
      },
      {
        label: "table",
        icon: diningArea_asset_18,
        width: 156,
        height: 155,
        layer: 1
      },
      {
        label: "table",
        icon: diningArea_asset_19,
        width: 95,
        height: 139,
        layer: 1
      },
      {
        label: "table",
        icon: diningArea_asset_20,
        width: 140,
        height: 139,
        layer: 1
      },
      // borrowed
      {
        label: "storage settee w/ sliding door",
        icon: livingArea_asset_14,
        width: 469,
        height: 121,
        layer: 1
      },
      {
        label: "storage settee w/ casement door",
        icon: livingArea_asset_15,
        width: 469,
        height: 127,
        layer: 1
      },
      {
        label: "storage settee w/ flip-up door",
        icon: livingArea_asset_16,
        width: 469,
        height: 103,
        layer: 1
      },
      {
        label: "settee cushion",
        icon: livingArea_asset_20,
        width: 70,
        height: 70,
        layer: 1
      }
    ]
  },
  bedroom: {
    label: "Bedroom",
    data: [
      {
        label: "king size 183cm x 191cm",
        icon: bedroom_asset_1,
        width: 280,
        height: 293,
        layer: 1
      },
      {
        label: "queen size 152cm x 191cm",
        icon: bedroom_asset_2,
        width: 233,
        height: 293,
        layer: 1
      },
      {
        label: "super single 107cm x 191cm",
        icon: bedroom_asset_3,
        width: 165,
        height: 293,
        layer: 1
      },
      {
        label: "single 91cm x 191cm",
        icon: bedroom_asset_4,
        width: 142,
        height: 293,
        layer: 1
      },
      {
        label: "king size w/ bedframe 203cm x 201cm",
        icon: bedroom_asset_5,
        width: 310,
        height: 307,
        layer: 1
      },
      {
        label: "queen size w/ bedframe 172cm x 201cm",
        icon: bedroom_asset_6,
        width: 263,
        height: 307,
        layer: 1
      },
      {
        label: "super single w/ bedframe 127cm x 201cm",
        icon: bedroom_asset_7,
        width: 196,
        height: 307,
        layer: 1
      },
      {
        label: "single w/ bedframe 111cm x 201cm",
        icon: bedroom_asset_8,
        width: 199,
        height: 335,
        layer: 1
      },
      {
        label: "bedhead",
        icon: bedroom_asset_9,
        width: 263,
        height: 16,
        layer: 2
      },
      {
        label: "bedhead w/ cushion",
        icon: bedroom_asset_10,
        width: 264,
        height: 28,
        layer: 2
      },
      // {
      //   label: "full height cabinet",
      //   icon: bedroom_asset_11,
      //   width: 94,
      //   height: 94,
      //   layer: 1
      // },
      {
        label: "dresser table",
        icon: bedroom_asset_12,
        width: 94,
        height: 67,
        layer: 1
      },
      {
        label: "sliding wardrobe",
        icon: bedroom_asset_13,
        width: 277,
        height: 125,
        layer: 1
      },
      {
        label: "casement wardrobe",
        icon: bedroom_asset_14,
        width: 276,
        height: 130,
        layer: 1
      },
      {
        label: "open-concept wardrobe",
        icon: bedroom_asset_15,
        width: 276,
        height: 101,
        layer: 1
      },
      {
        label: "L-shaped wardrobe",
        icon: bedroom_asset_16,
        width: 308,
        height: 308,
        layer: 1
      },
      // borrowed
      {
        label: "full height cabinet",
        icon: kitchen_asset_3,
        width: 95,
        height: 95,
        layer: 1
      },
      {
        label: "half height cabinet",
        icon: kitchen_asset_5,
        width: 95,
        height: 94,
        layer: 1
      }
    ]
  },
  livingArea: {
    label: "Living Area",
    data: [
      {
        label: 'TV 46" w: 1030mm',
        icon: livingArea_asset_1,
        width: 161,
        height: 35,
        layer: 2
      },
      {
        label: 'TV 48" w: 1080mm',
        icon: livingArea_asset_2,
        width: 168,
        height: 35,
        layer: 2
      },
      {
        label: 'TV 50" w: 1130mm',
        icon: livingArea_asset_3,
        width: 176,
        height: 35,
        layer: 2
      },
      {
        label: 'TV 55" w: 1240mm',
        icon: livingArea_asset_4,
        width: 192,
        height: 35,
        layer: 2
      },
      {
        label: 'TV 60" w: 1370mm',
        icon: livingArea_asset_5,
        width: 212,
        height: 35,
        layer: 2
      },
      {
        label: 'TV 65" w: 1450mm',
        icon: livingArea_asset_6,
        width: 225,
        height: 35,
        layer: 2
      },
      {
        label: "full height feature wall",
        icon: livingArea_asset_7,
        width: 321,
        height: 19,
        layer: 1
      },
      {
        label: "half height feature wall",
        icon: livingArea_asset_8,
        width: 321,
        height: 18,
        layer: 1
      },
      {
        label: "half height TV console",
        icon: livingArea_asset_9,
        width: 294,
        height: 99,
        layer: 1
      },
      {
        label: "suspended TV console",
        icon: livingArea_asset_10,
        width: 276,
        height: 80,
        layer: 1
      },
      {
        label: "open-concept full height cabinet",
        icon: livingArea_asset_11,
        width: 94,
        height: 94,
        layer: 1
      },
      {
        label: "open-concept half height cabinet",
        icon: livingArea_asset_12,
        width: 94,
        height: 94,
        layer: 1
      },
      {
        label: "piano",
        icon: livingArea_asset_13,
        width: 230,
        height: 94,
        layer: 1
      },
      {
        label: "storage settee w/ sliding door",
        icon: livingArea_asset_14,
        width: 469,
        height: 121,
        layer: 1
      },
      {
        label: "storage settee w/ casement door",
        icon: livingArea_asset_15,
        width: 469,
        height: 127,
        layer: 1
      },
      {
        label: "storage settee w/ flip-up door",
        icon: livingArea_asset_16,
        width: 469,
        height: 103,
        layer: 1
      },
      {
        label: "bay window w/o storage",
        icon: livingArea_asset_17,
        width: 469,
        height: 94,
        layer: 1
      },
      {
        label: "storage settee w/ sliding door",
        icon: livingArea_asset_18,
        width: 94,
        height: 123,
        layer: 1
      },
      {
        label: "storage settee w/ casement door",
        icon: livingArea_asset_19,
        width: 95,
        height: 127,
        layer: 1
      },
      {
        label: "settee cushion",
        icon: livingArea_asset_20,
        width: 70,
        height: 70,
        layer: 1
      },
      {
        label: "half height shoe cabinet",
        icon: livingArea_asset_21,
        width: 185,
        height: 127,
        layer: 1
      },
      {
        label: "half height shoe cabinet",
        icon: livingArea_asset_22,
        width: 185,
        height: 116,
        layer: 1
      },
      {
        label: "full height shoe cabinet",
        icon: livingArea_asset_23,
        width: 185,
        height: 127,
        layer: 1
      },
      {
        label: "full height shoe cabinet",
        icon: livingArea_asset_24,
        width: 185,
        height: 116,
        layer: 1
      },
      // {
      //   label: "shoes",
      //   icon: livingArea_asset_25,
      //   width: 44,
      //   height: 76,
      //   layer: 1
      // },
      {
        label: "sofa 300cm x 145cm",
        icon: livingArea_asset_26,
        width: 458,
        height: 223,
        layer: 1
      },
      {
        label: "sofa 300cm x 145cm",
        icon: livingArea_asset_27,
        width: 458,
        height: 223,
        layer: 1
      },
      {
        label: "sofa 230cm x 90cm",
        icon: livingArea_asset_28,
        width: 351,
        height: 139,
        layer: 1
      },
      {
        label: "sofa 160cm x 90cm",
        icon: livingArea_asset_29,
        width: 245,
        height: 140,
        layer: 1
      },
      {
        label: "sofa 95cm x 90cm",
        icon: livingArea_asset_30,
        width: 147,
        height: 139,
        layer: 1
      },
      {
        label: "sofa w/ legrest 95cm x 133cm",
        icon: livingArea_asset_31,
        width: 147,
        height: 205,
        layer: 1
      },
      {
        label: "carpet",
        icon: livingArea_asset_32,
        width: 286,
        height: 313,
        layer: 0
      },
      {
        label: "carpet",
        icon: livingArea_asset_33,
        width: 287,
        height: 314,
        layer: 0
      },
      {
        label: "carpet",
        icon: livingArea_asset_34,
        width: 299,
        height: 339,
        layer: 0
      },
      {
        label: "sofa 144cm x 85cm",
        icon: livingArea_asset_35,
        width: 222,
        height: 132,
        layer: 1
      },
      {
        label: "sofa 80cm x 80cm",
        icon: livingArea_asset_36,
        width: 125,
        height: 125,
        layer: 1
      },
      {
        label: "sofa 90cm x 90cm",
        icon: livingArea_asset_37,
        width: 140,
        height: 139,
        layer: 1
      },
      {
        label: "sofa 172cm x 44cm",
        icon: livingArea_asset_38,
        width: 264,
        height: 71,
        layer: 1
      },
      {
        label: "sofa 211cm x 148cm",
        icon: livingArea_asset_39,
        width: 322,
        height: 299,
        layer: 1
      },
      {
        label: "sofa 211cm x 173cm",
        icon: livingArea_asset_40,
        width: 323,
        height: 266,
        layer: 1
      },
      {
        label: "sofa 192cm x 200cm",
        icon: livingArea_asset_41,
        width: 293,
        height: 305,
        layer: 1
      },
      {
        label: "sofa 300cm x 148cm",
        icon: livingArea_asset_42,
        width: 455,
        height: 227,
        layer: 1
      },
      {
        label: "sofa 215cm x 85cm",
        icon: livingArea_asset_43,
        width: 328,
        height: 133,
        layer: 1
      },
      {
        label: "sofa 148cm x 148cm",
        icon: livingArea_asset_44,
        width: 229,
        height: 227,
        layer: 1
      },
      {
        label: "sofa 240cm x 180cm",
        icon: livingArea_asset_45,
        width: 366,
        height: 276,
        layer: 1
      },
      {
        label: "sofa 302cm x 90cm",
        icon: livingArea_asset_46,
        width: 459,
        height: 140,
        layer: 1
      },
      {
        label: "sofa 240cm x 115cm",
        icon: livingArea_asset_47,
        width: 367,
        height: 178,
        layer: 1
      },
      {
        label: "chair",
        icon: livingArea_asset_48,
        width: 73,
        height: 73,
        layer: 1
      },
      {
        label: "chair",
        icon: livingArea_asset_49,
        width: 79,
        height: 79,
        layer: 1
      },
      {
        label: "stool",
        icon: livingArea_asset_50,
        width: 64,
        height: 64,
        layer: 1
      }
    ]
  },
  kitchen: {
    label: "Kitchen",
    data: [
      {
        label: "lower cabinet/table",
        icon: kitchen_asset_1,
        width: 329,
        height: 94,
        layer: 1
      },
      {
        label: "top hung cabinet",
        icon: kitchen_asset_2,
        width: 333,
        height: 49,
        layer: 3
      },
      {
        label: "full height cabinet",
        icon: kitchen_asset_3,
        width: 95,
        height: 95,
        layer: 1
      },
      {
        label: "full height cabinet w/ oven",
        icon: kitchen_asset_4,
        width: 95,
        height: 102,
        layer: 1
      },
      {
        label: "half height cabinet",
        icon: kitchen_asset_5,
        width: 95,
        height: 94,
        layer: 1
      },
      {
        label: "oven",
        icon: kitchen_asset_6,
        width: 79,
        height: 8,
        layer: 1
      },
      {
        label: "fridge",
        icon: kitchen_asset_7,
        width: 103,
        height: 200,
        layer: 1
      },
      {
        label: "fridge",
        icon: kitchen_asset_8,
        width: 123,
        height: 202,
        layer: 1
      },
      {
        label: "fridge",
        icon: kitchen_asset_9,
        width: 155,
        height: 184,
        layer: 1
      },
      {
        label: "washing machine",
        icon: kitchen_asset_10,
        width: 94,
        height: 95,
        layer: 1
      },
      {
        label: "dryer",
        icon: kitchen_asset_11,
        width: 94,
        height: 95,
        layer: 1
      },
      {
        label: "hob w: 280mm",
        icon: kitchen_asset_12,
        width: 44,
        height: 80,
        layer: 2
      },
      {
        label: "hob w: 770mm",
        icon: kitchen_asset_13,
        width: 118,
        height: 78,
        layer: 2
      },
      {
        label: "hob w: 850mm",
        icon: kitchen_asset_14,
        width: 131,
        height: 79,
        layer: 2
      },
      {
        label: "kitchen sink w: 500mm",
        icon: kitchen_asset_15,
        width: 78,
        height: 78,
        layer: 2
      },
      {
        label: "kitchen sink w: 750mm",
        icon: kitchen_asset_16,
        width: 116,
        height: 80,
        layer: 2
      },
      {
        label: "kitchen sink w: 850mm",
        icon: kitchen_asset_17,
        width: 131,
        height: 81,
        layer: 2
      },
      {
        label: "hob w: 780mm",
        icon: kitchen_asset_18,
        width: 122,
        height: 69,
        layer: 2
      },
      {
        label: "hob w: 780mm",
        icon: kitchen_asset_19,
        width: 122,
        height: 76,
        layer: 2
      },
      {
        label: "hob w: 785mm",
        icon: kitchen_asset_20,
        width: 122,
        height: 76,
        layer: 2
      },
      {
        label: "hob w: 832mm",
        icon: kitchen_asset_21,
        width: 129,
        height: 77,
        layer: 2
      },
      {
        label: "hob w: 682mm",
        icon: kitchen_asset_22,
        width: 107,
        height: 77,
        layer: 2
      }
    ]
  },
  bathroom: {
    label: "Bathroom",
    data: [
      {
        label: "wash basin",
        icon: bathroom_asset_1,
        width: 77,
        height: 80,
        layer: 2
      },
      {
        label: "wash basin",
        icon: bathroom_asset_2,
        width: 101,
        height: 81,
        layer: 2
      },
      {
        label: "wash basin",
        icon: bathroom_asset_3,
        width: 78,
        height: 77,
        layer: 2
      },
      {
        label: "toliet bowl",
        icon: bathroom_asset_4,
        width: 79,
        height: 107,
        layer: 1
      },
      {
        label: "shower head",
        icon: bathroom_asset_5,
        width: 95,
        height: 95,
        layer: 5
      },
      {
        label: "suspended vanity",
        icon: bathroom_asset_6,
        width: 185,
        height: 94,
        layer: 1
      },
      {
        label: "shower area",
        icon: bathroom_asset_7,
        width: 179,
        height: 212,
        layer: 0
      },
      {
        label: "shower door",
        icon: bathroom_asset_8,
        width: 150,
        height: 9,
        layer: 1
      },
      {
        label: "shower door",
        icon: bathroom_asset_9,
        width: 186,
        height: 81,
        layer: 1
      },
    ]
  }
}

/* stage data */
export const StageData = {
  // 24" monitor standard
  standardW: floorplanH * floorplanWHRatio * 1.5,
  standardW_screenshot: floorplanH * floorplanWHRatio,  // narrow down when taking screenshot
  standardH: floorplanH,
  x: 0,
  y: 0
}

/* floorplan data */
export const FloorPlanData = {
  width: floorplanH * floorplanWHRatio,
  height: floorplanH
}