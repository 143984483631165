import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import SelectionPage from './components/Layouts/SelectionPage';
import Stepper from './components/Stepper/Stepper';
import Popup from './components/Popup/Popup';
import Editor from './Editor/Editor';
import { floorplanStorageBaseURL } from './components/Data/FloorplanData';
import { firestore } from './Firebase/Firebase';  // must include in app.js so it can be initialized
import { fetchDataFromFirebase } from './components/Utilities/Utilities';
import './App.css';
import { reject } from 'lodash';
// others
const axios = require('axios');

/* stepper */
const stepperPageLabels = ["Development", "Block", "Room count", "Floor plan", "Design editor"];

function App() {

  /* props/consts/vars */
  const [userData, setUserData] = useState({
    developmentIndex: -1,
    developmentId: "",
    developmentLabel: "",
    blockIndex: -1,
    blockId: "",
    blockLabel: "",
    roomCountIndex: -1,
    roomCountId: "",
    roomCountLabel: "",
    floorPlanIndex: -1,
    floorPlanId: "",
    floorPlanLabel: "",
    floorPlanType: "",
    customerName: "",
    customerPhone: ""
  });
  const [validateState, setValidateState] = useState(["", ""]); // we only have 2 fill in checks
  const [currStep, setCurrStep] = useState(0);
  const [currState, setCurrState] = useState("set details");  // set details, personal info, editor
  const [developmentDat2, setDevelopmentDat2] = useState(null); // from Firebase
  const [blockData, setBlockData] = useState(null); // from Firebase
  const [dataState, setDataState] = useState("success");

  /* functions */
  useEffect(() => {

    // fetch development data
    fetchDataFromFirebase(!developmentDat2 && currStep === 0, "data/development", dataState, setDataState, (docData) => {
      let returnArr = docData.developmentList.map(devObj => {
        return { id: devObj.id, label: devObj.label, blocks: devObj.blocks };
      });
      setDevelopmentDat2(returnArr);
      setDataState("success");
    });

    // for quick access to editor (uncomment, no need comment anything else)
    // if (developmentDat2 && dataState !== "loading") {
    //   getQuickUserData("senja_heights", "640A", "3rm", "#281");
    // }
  });

  /* for quick testing */
  function getQuickUserData(developmentId, blockId, roomCountId, floorPlanId) {
    // development
    let dIndex = developmentDat2.findIndex((obj) => obj.id === developmentId);
    // block
    let bIndex = developmentDat2[dIndex].blocks.findIndex((obj) => obj.id === blockId);

    // fetch block data
    fetchDataFromFirebase(true, `data/${developmentId}-${blockId}`, dataState, setDataState, (blockData) => {

      // room count
      let rIndex = blockData.roomCounts.findIndex((obj) => obj.id === roomCountId);
      // floor plans
      let fIndex = blockData.roomCounts[rIndex].floorPlans.findIndex((obj) => obj.id === floorPlanId);

      // object
      setUserData({
        developmentIndex: dIndex,
        developmentId: developmentId,
        developmentLabel: developmentDat2[dIndex].label,
        blockIndex: bIndex,
        blockId: blockId,
        blockLabel: developmentDat2[dIndex].blocks[bIndex].label,
        roomCountIndex: rIndex,
        roomCountId: roomCountId,
        roomCountLabel: blockData.roomCounts[rIndex].label,
        floorPlanIndex: fIndex,
        floorPlanId: floorPlanId,
        floorPlanLabel: blockData.roomCounts[rIndex].floorPlans[fIndex].label,
        floorPlanType: blockData.roomCounts[rIndex].floorPlans[fIndex].type,
        customerName: "TEST Tan Yie Cher",
        customerPhone: "+65 8776-1091"
      });
      setCurrState("editor");
    });
  }

  // frontend specific function
  function onClickOption(index, label) {
    // not needed, we only set value on btn next clicked
  }

  function resetEverything() {
    setUserData({
      developmentIndex: -1,
      developmentId: "",
      blockIndex: -1,
      blockId: "",
      roomCountIndex: -1,
      roomCountId: "",
      floorPlanIndex: -1,
      floorPlanId: "",
      customerName: "",
      customerPhone: "",
    });
    setValidateState(["", ""]); // we only have 2 fill in checks
    setCurrStep(0);
    setCurrState("set details");  // set details, personal info, editor
    setDataState("success");
  }

  function onClosePopup() {
    // on close but not submit details
    setCurrState("set details");
  }

  function drawStepper(currStep) {
    return <Stepper
      steps={stepperPageLabels}
      currStep={currStep}
    />
  }

  function buildUrlForFloorplan(floorPlanType) {
    // build url from floorplan name
    return `${floorplanStorageBaseURL}/${userData.developmentId}/svg/${floorPlanType}-nokitchenwall.svg`;
  }

  function onSubmitPersonalInfo() {
    // client side validation
    let proceed = true;
    const newValidateState = ["", ""];
    if (userData.customerName === "") {
      newValidateState[0] = "Name must be entered";
      proceed = false;
    }
    // no  phone and/or phone is lesser than 7 digits
    if (!userData.customerPhone || userData.customerPhone.length < 12) {
      newValidateState[1] = "Please enter phone number";
      proceed = false;
    }
    // submit data
    if (proceed) {
      setDataState("loading");
      // mock
      // const interval = setInterval(() => {
      //   setDataState("success");
      //   setCurrState("editor");
      //   clearInterval(interval);
      // }, 2000);
      // real
      const url = "https://us-central1-sg-interior-tool.cloudfunctions.net/widgets/details";
      // const url = 'http://localhost:5000/details';
      let fd = new FormData();
      fd.append('customerName', userData.customerName);
      fd.append('customerPhone', userData.customerPhone);
      fd.append('customerDevelopment', userData.developmentLabel);
      fd.append('customerBlock', userData.blockLabel);
      fd.append('customerRoomCount', userData.roomCountLabel);
      fd.append('customerFloorPlan', userData.floorPlanLabel);
      axios.post(url, fd)
      .then(function (response) {
        // success
        console.log("success");
        setDataState("success");
        setCurrState("editor");
      })
      .catch(function (error) {
        console.log(error);
      });
    } else {
      setValidateState(newValidateState);
    }
  }

  function drawPopup() {
    let open = currState === "personal info";
    const enablePopupOptions = currState === "personal info" && dataState === "success";  // when loading disable all buttons
    // dataState === "loading"
    return <Popup
      open={open}
      isLoading={dataState === "loading"}
      title={dataState === "loading" ? "Loading editor" : "Enter details"}
      mainText="Please enter the following details so that we can facilitate the design process for you."
      fields={[
        {
          label: "Name",
          type: "text",
          onBlur: (text) => {
            if (enablePopupOptions) {
              let newUserData = cloneDeep(userData);
              newUserData.customerName = text;
              setUserData(newUserData);
            }
          }
        },
        {
          label: "Phone",
          type: "phone",
          onBlur: (text) => {
            if (enablePopupOptions) {
              let newUserData = cloneDeep(userData);
              newUserData.customerPhone = text;
              setUserData(newUserData);
            }
          }
        }
      ]}
      onClose={enablePopupOptions ? onClosePopup : null}
      onProceed={enablePopupOptions ? onSubmitPersonalInfo : null}
      errorMessages={validateState}
    />
  }

  function onBack() {

    let newUserData = cloneDeep(userData);
    if (currStep - 1 === 0) { // development data
      newUserData.developmentIndex = -1;
      newUserData.developmentId = "";
      newUserData.developmentLabel = "";
    } else if (currStep - 1 === 1) {  // block data
      newUserData.blockIndex = -1;
      newUserData.blockId = "";
      newUserData.blockLabel = "";
      setBlockData(null); // re-fetch data again if needed
    } else if (currStep - 1 === 2) {  // room count data
      newUserData.roomCountIndex = -1;
      newUserData.roomCountId = "";
      newUserData.roomCountLabel = "";
    } else if (currStep - 1 === 3) {  // floor plan values
      newUserData.floorPlanIndex = -1;
      newUserData.floorPlanId = "";
      newUserData.floorPlanLabel = "";
      newUserData.floorPlanType = "";
    }

    // decrement the step
    setUserData(newUserData);
    setCurrStep(currStep - 1);
  }

  function onNext(index, label) {

    let newUserData = cloneDeep(userData);
    if (currStep === 0) { // development data
      newUserData.developmentIndex = index;
      newUserData.developmentId = developmentDat2[index].id;
      newUserData.developmentLabel = developmentDat2[index].label;
      setUserData(newUserData);
      setCurrStep(currStep + 1);
    } else if (currStep === 1) {  // block data
      newUserData.blockIndex = index;
      newUserData.blockId = developmentDat2[userData.developmentIndex].blocks[index].id;
      newUserData.blockLabel = developmentDat2[userData.developmentIndex].blocks[index].label;
      // fetch block data
      fetchDataFromFirebase(newUserData.blockId !== "" && !blockData, `data/${userData.developmentId}-${newUserData.blockId}`, dataState, setDataState, (docData) => {
        setUserData(newUserData);
        setBlockData(docData);
        setCurrStep(currStep + 1);
        setDataState("success");
      });
    } else if (currStep === 2) {  // room count data
      newUserData.roomCountIndex = index;
      newUserData.roomCountId = blockData.roomCounts[index].id;
      newUserData.roomCountLabel = blockData.roomCounts[index].label;
      setUserData(newUserData);
      setCurrStep(currStep + 1);
    } else if (currStep === 3) {  // floor plan data
      // validate fail
      newUserData.floorPlanIndex = index;
      newUserData.floorPlanId = blockData.roomCounts[userData.roomCountIndex].floorPlans[index].id;
      newUserData.floorPlanLabel = blockData.roomCounts[userData.roomCountIndex].floorPlans[index].label;
      newUserData.floorPlanType = blockData.roomCounts[userData.roomCountIndex].floorPlans[index].type;
      setUserData(newUserData);
      setCurrState("personal info");
    }
  }

  /* render */
  return (
    <div className="App">

      {/* set details + personal info stage */}
      {currState === "set details" || currState === "personal info" ?
      <div className="flex-vertical-page align-center">

        {/* development */}
        {currStep === 0 ? <SelectionPage
          title="Select your development"
          drawStepper={() => { return drawStepper(currStep) }}
          data={developmentDat2 ? developmentDat2.map((d, index) => {
            return { value: index, label: d.label }
          }) : null}
          selectionType="searchbar"
          onClick={(index, label) => { onClickOption(index, label) }}
          isFirstPage
          dataState={dataState}
          onNext={onNext}
          onBack={onBack} /> : null}

        {/* block */}
        {currStep === 1 ? <SelectionPage
          title="Select your block"
          drawStepper={() => { return drawStepper(currStep) }}
          data={developmentDat2 ? developmentDat2[userData.developmentIndex].blocks.map((b, index) => {
            return { value: index, label: b.label }
          }) : null}
          selectionType="boxsearch"
          onClick={onClickOption}
          dataState={dataState}
          onNext={onNext}
          onBack={onBack} /> : null}

        {/* room count */}
        {currStep === 2 ? <SelectionPage
          title="Select your room count"
          drawStepper={() => { return drawStepper(currStep) }}
          data={blockData ? blockData.roomCounts.map((rc, index) => {
            return { value: index, label: rc.label }
          }) : null}
          selectionType="boxsearch"
          onClick={onClickOption}
          dataState={dataState}
          onNext={onNext}
          onBack={onBack} /> : null}

        {/* floorplan */}
        {currStep === 3 ? <SelectionPage
          title="Select your floorplan"
          drawStepper={() => { return drawStepper(currStep) }}
          data={blockData ? blockData.roomCounts[userData.roomCountIndex].floorPlans.map((fp, index) => {
            return { value: index, label: fp.label, imgUrl: buildUrlForFloorplan(fp.type) }
          }) : null}
          selectionType="img_boxsearch"
          onClick={onClickOption}
          dataState={dataState}
          onNext={onNext}
          onBack={onBack} /> : null}

        {/* personal info popup */}
        {drawPopup()}

      </div> : null}

      {/* set details + personal info stage */}
      {currState === "editor" ?
        <div className="flex-vertical-page align-center">

          {/* editor */}
          <Editor
            userData={userData}
            resetEverything={resetEverything}
          />

        </div> : null}

    </div>
  );
}

export default App;
