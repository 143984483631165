import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiPhoneNumber from 'material-ui-phone-number';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Popup.css';
import '../../App.css';

/* popup */
export default function Popup(props) {

  /* props/consts/vars */
  const { open, isLoading, title, mainText, fields, errorMessages, onClose, onProceed } = props;

  /* functions */
  function drawField(type, label, onBlur, index) {
    const errMsg = errorMessages && errorMessages[index] ? errorMessages[index] : "";
    if (type === "text") {
      return <TextField
        error={errMsg !== ""}
        autoFocus
        margin="dense"
        id={label}
        label={label}
        type="text"
        fullWidth
        onChange={(e) => onBlur(e.target.value)}
        helperText={errMsg}
      />
    } else if (type === "email") {
      return <TextField
        error={errMsg !== ""}
        autoFocus
        margin="dense"
        id={label}
        label={label}
        type="email"
        fullWidth
        onChange={(e) => onBlur(e.target.value)}
        helperText={errMsg}
      />
    } else if (type === "phone") {
      return <div style={{ marginTop: '16px' }}>
        <MuiPhoneNumber className="phone-input" defaultCountry={'sg'} onBlur={(e) => onBlur(e.target.value)} />
        {errMsg ? <div className="horizontal-orientation"><div className="inset-text" style={{ color: "red" }}>{errMsg}</div></div> : null}
      </div>
    } else if (type === "textarea") {
      return <TextField
      error={errMsg !== ""}
      autoFocus
      margin="dense"
      id={label}
      label={label}
      type="text"
      fullWidth
      multiline
      rows={6}
      variant="outlined"
      onChange={(e) => onBlur(e.target.value)}
      helperText={errMsg}
    />
    }
  }

  function onClickProceed() {
    // console.log("asdadasdasd");
    // // validation
    // if (!errorMessages) {
    //   onProceed();
    // } else if (typeof errorMessages === "object") {
    //   // make sure no validation errors
    //   let proceed = true;
    //   for (var e in errorMessages) {
    //     if (e) {
    //       proceed = false;
    //       break;
    //     }
    //   }
    //   if (proceed) {
    //     onProceed();
    //   }
    // }
    onProceed();
  }

  /* render */
  return (
    <Dialog
      fullWidth={false}
      maxWidth="lg"
      open={open}
      aria-labelledby="max-width-dialog-title"
    >
      <div className="popup-body">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>

        {/* state */}
        {isLoading ?
          <DialogContent className="popup-small-content-body align-center">
            <div className="full-container align-center">
              <CircularProgress />
            </div>
          </DialogContent> :
          <DialogContent className="popup-small-content-body align-center">
            <DialogContentText>{mainText}</DialogContentText>
            {fields ? <div className="popup-text-body">
              {
                fields.map((val, index) => {
                  return <div style={{ width: '100%' }} key={index}>
                    {drawField(val.type, val.label, val.onBlur, index)}
                  </div>
                })
              }
            </div> : null}
          </DialogContent>}

        <DialogActions className="popup-footer">
          {onClose ? <Button onClick={onClose} color="primary">
            Cancel
          </Button> : null}
          {onProceed ? <Button onClick={onClickProceed} variant="contained" color="primary">
            Proceed
          </Button> : null}
        </DialogActions>
      </div>
    </Dialog>
  );
}
