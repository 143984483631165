import { firestore } from '../../Firebase/Firebase';

// fetch data from Firebase helper function (set dataState yourself)
export function fetchDataFromFirebase(conditionToFetch, docPath, dataState, setDataState, callback) {
    if (conditionToFetch && dataState !== "loading") {
      setDataState("loading");
      firestore.doc(docPath).get().then((doc) => {
        const docData = doc.data();
        callback(docData);
      })
      .catch((error) => {
        console.log("Error fetching data: ", error);
        setDataState("success");
      });
    }
  }