import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Stage, Layer, Rect, Image, Transformer } from 'react-konva';
import useImage from 'use-image';
import '../../App.css';

/* draggable object */
export const Rectangle = ({ shapeProps, mouse, divRef, isSelected, onSelect, onChange, dragOnCreate, imgData }) => {

  /* props/consts/vars */
  const [image] = useImage(imgData.icon);
  const shapeRef = useRef();
  const trRef = useRef();
  const [beginDragOnce, setBeginDragOnce] = useState(dragOnCreate); // make state draggable true on first create
  const [finishDragOnce, setFinishDragOnce] = useState(dragOnCreate); // make centered on cursor on first drag
  const [hoverMode, setHoverMode] = useState(true);

  /* functions */
  useEffect(() => {
    // console.log("object use effect");
    if (beginDragOnce) {
      shapeRef.current.startDrag();
      setBeginDragOnce(false);
    }
    if (isSelected && trRef) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  /* render */
  return (
    <Fragment>
      <Image
        image={image}
        onClick={(e) => onSelect(e, shapeRef.current)}
        onTap={(e) => onSelect(e, shapeRef.current)}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragStart={(e) => {
          shapeRef.current.strokeEnabled(true);
        }}
        onMouseEnter={(e) => {
          if (hoverMode) {
            shapeRef.current.strokeEnabled(true);
          }
        }}
        onMouseOut={(e) => {
          if (hoverMode) {
            shapeRef.current.strokeEnabled(false);
          }
        }}
        onDragMove={(e) => {
          if (finishDragOnce) {
            // called before any scaling or stuff is done
            let scrollTop = 0.0;
            let scrollLeft = 0.0;
            let mousePos = { x: -1000.0, y: -1000.0 };
            if (divRef.current) {
              scrollTop = divRef.current.scrollTop;
              scrollLeft = divRef.current.scrollLeft;
            }
            if (mouse && (mouse.x !== null || mouse.y !== null)) {
              mousePos.x = mouse.x;
              mousePos.y = mouse.y;
            }
            shapeRef.current.absolutePosition({x: mousePos.x + scrollLeft, y: mousePos.y + scrollTop});
          }
        }}
        onDragEnd={(e) => {
          shapeRef.current.strokeEnabled(false);
          if (finishDragOnce) {
            setFinishDragOnce(false);
          }
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformStart={(e) => {
          // disable hover mode (it will interfere with transformation)
          setHoverMode(false);
          shapeRef.current.strokeEnabled(false);
        }}
        onTransformEnd={(e) => {
          
          // enable hover mode
          setHoverMode(true);

          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          // set minimal value
          const w = Math.max(5, node.width() * scaleX);
          const h = Math.max(5, node.height() * scaleY);

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);

          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            offsetX: w / 2,
            offsetY: h / 2,
            width: w,
            height: h,
          });
        }}
      />
      {/* basic for onMouseEnter and onMouseMove to work */}
      <Transformer
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
      />
      {/* for transformation */}
      {(isSelected && (
        <Transformer
          ref={trRef}
          anchorStroke="red"
          borderStroke="red"
          rotationSnaps={[0, 90, 180, 270]}
          enabledAnchors={['middle-right', 'middle-left']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      ))}
    </Fragment>
  );
};