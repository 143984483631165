import React, { useState } from 'react';
// UI
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import './../../App.css';
import './SelectionPage.css';
// image
import companyLogo from '../../Assets/logo.svg';

/*
function: display a selection to fill in a certain data
data type: [{value: string, label: string},...]
*/
function App(props) {

  /* props/consts/vars */
  const { title, drawStepper, data, selectionType, isFirstPage, onClick, dataState, onNext, onBack } = props;
  const [selectedState, setSelectedState] = useState(null);

  /* functions */
  function onSelectionSelected(index, label) {
    setSelectedState({ index: index, label: label });
    onClick(index, label);
  }

  /* render */
  return (
    <div className="slection-page align-center">

      {/* logo */}
      <div className="selection-display-logo">
        <div style={{ width: '170px', height: '70px', backgroundImage: `url(${companyLogo})`,
          backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} />
      </div>

      {/* title */}
      <div className="selection-title padding-large">
        <div className="h1-title-text">{title}</div>
      </div>

      {/* stepper */}
      <div className="selection-stepper-container">
        {drawStepper()}
      </div>

      {/* select items */}
      {selectionType === "searchbar" ? <div className="selection-search-container">
        <form noValidate autoComplete="off" style={{ width: '70%' }}>
          <Select
            isDisabled={!data}
            defaultValue={selectedState}
            onChange={(s) => { onSelectionSelected(s.value, s.label) }}
            options={data}
            placeholder="Type here..."
          />
        </form>
        {dataState === "loading" ? <div class="align-center" style={{ padding: '16px' }}><CircularProgress /></div> : null}
      </div> : null}

      {/* box select items */}
      {selectionType === "boxsearch" ? <div className="selection-box-select-container">
        {
          data && dataState !== "loading" ? data.map((val, index) => {
            let className = selectedState && selectedState.index === index ? "selection-box-selected" : "selection-box";
            return <div key={index} className={className} onClick={() => {
              onSelectionSelected(index, val.label)
            }}>
              <div className="h3-title-text text-unselectable">{val.label}</div>
            </div>
          }) : null
        }
        {dataState === "loading" ? <div class="align-center" style={{ padding: '16px' }}><CircularProgress /></div> : null}
      </div> : null}

      {/* image box select items */}
      {selectionType === "img_boxsearch" ? <div className="selection-img-box-select-container">
        {
          data && dataState !== "loading" ? data.map((val, index) => {
            let className = selectedState && selectedState.index === index ? "selection-img-box-selected vertical-orientation" : "selection-img-box vertical-orientation";
            return <div key={index} className={className} onClick={() => {
              onSelectionSelected(index, val.label)
            }}>
              <div className="h3-title-text text-unselectable">{val.label}</div>
              <div style={{ width: "475px", height: "320px", backgroundImage: `url(${val.imgUrl})`,
                  backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '190% 190%'
                }} />
            </div>
          }) : null
        }
        {dataState === "loading" ? <div class="align-center" style={{ padding: '16px' }}><CircularProgress /></div> : null}
      </div> : null}

      {/* loading icon */}
      

      {/* buttons */}
      <div className="selection-nav-buttons-container">
        <Button
          variant="contained"
          color="primary"
          className="selection-nav-button"
          disabled={isFirstPage}
          onClick={onBack}
          endIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="selection-nav-button"
          onClick={() => { onNext(selectedState.index, selectedState.label) }}
          disabled={!selectedState}
          endIcon={<ArrowForwardIcon />}
        >
          Proceed
        </Button>
      </div>

    </div>
  );
}

export default App;