import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Stage, Layer, Rect, Image, Transformer } from 'react-konva';
import useImage from 'use-image';
import '../../App.css';

/* non-draggable object */
export const StaticItem = ({ shapeProps, imgData }) => {

  /* props/consts/vars */
  const [image] = useImage(imgData, 'Anonymous');  // for using Google cloud storage
  // const [image] = useImage(imgData);

  /* functions */
  useEffect(() => {
  });

  /* render */
  return (
    <Fragment>
      <Image
        image={image}
        {...shapeProps}
        draggable={false}
      />
    </Fragment>
  );
};