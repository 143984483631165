import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { ItemsData } from '../Item/ItemData';
import './SelectionPane.css';
import '../../App.css';

/* Editor */
export default function Editor(props) {

  /* props/consts/vars */
  const { createNewItem, onClickSubmit } = props;
  const [selectedCategoryKey, setSelectedCategoryKey] = useState("bathroom");

  /* functions */
  function onMouseDownOption(e, item) {
    // create item bathroom > toliet
    createNewItem(e.clientX, e.clientY, item);
  }

  function onClickCategory(key) {
    setSelectedCategoryKey(key);
  }

  function submitDrawing() {
    onClickSubmit();
  }

  /* render */
  return (
    <div className="flex-vertical-page align-center-top" style={{ backgroundColor: "#929292" }}>

      {/* menu selection */}
      <div className="flex-vertical">
        <div className="selection-pane-subtitle-container align-center">
          <div className="h3-title-text font-white text-unselectable">Categories</div>
        </div>
        <div className="selection-pane-wrap-container">
          {
            Object.keys(ItemsData).map((val, index) => {
              return <div className={selectedCategoryKey === val ? "selection-pane-category-button-selected" : "selection-pane-category-button"} key={index} onClick={() => onClickCategory(val)}>
                <div className="standard-text text-unselectable">{ItemsData[val].label}</div>
              </div>
            })
          }
        </div>
      </div>

      {/* choice selection */}
      <div className="selection-pane-subtitle-container align-center">
        <div className="standard-text font-white text-unselectable">Drag and drop</div>
      </div>
      <div className="selection-pane-option-outer-container">
        <div className="selection-pane-options-wrap-container">
          {
            ItemsData[selectedCategoryKey].data.map((val, index) => {
              return <div className="selection-pane-option-button" key={index} onMouseDown={(e) => onMouseDownOption(e, val)}>
                <div style={{
                  width: '50px', height: '50px', backgroundImage: `url(${val.icon})`,
                  backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'
                }} />
                <div className="small-text text-unselectable">{val.label}</div>
              </div>
            })
          }
        </div>
      </div>

      {/* submit */}
      <div className="selection-pane-bottom-menu">
        <Button
          variant="contained"
          color="primary"
          className="selection-nav-button"
          onClick={submitDrawing}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}