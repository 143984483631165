// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import { firebase } from '@firebase/app';

// Add the Firebase products that you want to use
require("firebase/firestore");

var firebaseConfig = {
  apiKey: "AIzaSyB6Tyrl_bmp7lACbV-BMt_0WWLHvQ0XBRE",
  authDomain: "sg-interior-tool.firebaseapp.com",
  projectId: "sg-interior-tool"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // firebase.analytics();
  console.log("Called at the very start");
}

// export
export const firestore = firebase.firestore();